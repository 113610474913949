/* eslint-disable max-len */
/* eslint-disable max-lines */
import ContentWrapper from '@scandipwa/scandipwa/src/component/ContentWrapper';
import Html from '@scandipwa/scandipwa/src/component/Html';
import Link from '@scandipwa/scandipwa/src/component/Link';
import { AddToCart } from '@scandipwa/scandipwa/src/component/Product/Product.component';
import { CategoryPageLayout } from '@scandipwa/scandipwa/src/route/CategoryPage/CategoryPage.config';
import { Suspense } from 'react';

import {
    ProductType as SourceProductType,
} from 'SourceComponent/Product/Product.config';
import {
    ProductActionsComponent as SourceProductActionsComponent,
} from 'SourceComponent/ProductActions/ProductActions.component';
import { ReactElement } from 'SourceType/Common.type';
import { isCrawler, isSSR } from 'SourceUtil/Browser';
import { lowPriorityLazy } from 'SourceUtil/Request/LowPriorityRender';
import { getStoreState } from 'SourceUtil/Store';

import './ProductActions.style';

export const TrustPilotReviews = lowPriorityLazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "trustpilot-misc" */
    'Component/TrustPilotReviews'
));

/** @namespace Inov8/Component/ProductActions/Component */
export class ProductActionsComponent extends SourceProductActionsComponent {
    renderProductActionsTop(): ReactElement {
        const { ConfigReducer: { code } = {} } = getStoreState();
        const { product } = this.props;

        return (
            <>
                { product.sku !== 'ECOM-SUB' && <TrustPilotReviews product={ product } noClick productSchema /> }
                { this.renderShortDescription() }
                { code !== 'row' && (
                    <>
                    { this.renderPriceWithGlobalSchema() }
                    </>
                ) }
            </>
        );
    }

    renderPriceMatchPromise(): ReactElement {
        return (
            <div
              block="ProductActions"
              elem="PriceMatchPromise"
            >
                <p><strong>Price Match Promise</strong></p>
               <p>
                { __('If you find your product cheaper somewhere else, let us know via Live Chat and we\'ll match it.') }
                <Link to="/price-match-promise"> Find out more</Link>
               </p>
            </div>
        );
    }

    renderAddToCartActionBlock(): ReactElement {
        const { product } = this.props;
        const { ConfigReducer: { code } = {} } = getStoreState();

        return (
            <>
            <div
              block="ProductActions"
              elem="AddToCartWrapper"
              mods={ { isPrerendered: isSSR() || isCrawler() } }
            >
                { this.renderAddToCartButton() }
            </div>
            { product.sku !== 'ECOM-SUB' && (
                <>
                    <div block="ProductActions" elem="ActionButtons">
                        { this.renderWishlistButton() }
                        { this.renderCompareButton() }
                    </div>
                    { (code === 'uk' || code === 'us') && (
                        <>
                            { this.renderPriceMatchPromise() }
                        </>
                    ) }
                </>
            ) }
            </>
        );
    }

    renderAddToCartButton(layout = CategoryPageLayout.GRID): ReactElement {
        const {
            addToCart,
            inStock,
            quantity,
            getActiveProduct,
            updateSelectedValues,
        } = this.props;
        const { ConfigReducer: { code } = {} } = getStoreState();

        if (code === 'row') {
            return null;
        }

        return (
            <Suspense fallback={ null }>
                <AddToCart
                  blockColor="primary"
                  mix={ { block: this.className, elem: 'AddToCart' } }
                  addToCart={ addToCart }
                  isDisabled={ !inStock }
                  isIconEnabled={ false }
                  layout={ layout }
                  updateSelectedValues={ updateSelectedValues }
                  quantity={ quantity }
                  product={ getActiveProduct() }
                />

            </Suspense>
        );
    }

    renderPreorderBlock(): ReactElement {
        return (
            <div block="ProductActions-CheckboxWrapper data-hard-coded">
                <input block="PreorderCheckbox" type="checkbox" />
                <span>I am aware this is a pre-order item</span>
            </div>
        );
    }

    renderShortDescription() : ReactElement {
        const {product: {short_description}} = this.props;
        const { html } = short_description || {};

        if (!html) {
            return null;
        }

        return (
            <div
              block="ProductActions"
              elem="LongDescription"
              mods={ { type: 'long' } }
              aria-label="Product long description"
            >
                <Html content={ html } />
            </div>
        );
    }

    renderLongDescription(): ReactElement {
        const { product: { description } } = this.props;
        const { html } = description || {};

        if (!html) {
            return null;
        }

        return (
            <section
              block="ProductActions"
              elem="Section"
              mods={ { type: 'long' } }
              aria-label="Product long description"
            >
                <div block="ProductActions" elem="LongDescription">
                    <Html content={ html } />
                </div>
            </section>
        );
    }

    renderProductAlerts(): ReactElement {
        return null;
    }

    renderReviewButton(): ReactElement {
        return null;
    }

    renderDesktop(): ReactElement {
        return (
            <>
                { this.renderProductActionsTop() }
                { this.renderConfigurableOptions() }
                { this.renderAddToCartActionBlock() }
            </>
        );
    }

    renderMobile(): ReactElement {
        const { product: { type_id: type }, product } = this.props;
        const isWithoutPriceTotal = type === SourceProductType.GROUPED;
        const { ConfigReducer: { code } = {} } = getStoreState();

        return (
            <>
            <ContentWrapper>
                { product.sku !== 'ECOM-SUB' && <TrustPilotReviews product={ product } noClick productSchema /> }
                { this.renderShortDescription() }
            </ContentWrapper>
                { code !== 'row' && (
                    <div block="ProductActions" elem="ActionsWrapper" mods={ { isWithoutPriceTotal } }>
                        { this.renderPriceWithGlobalSchema() }
                    </div>
                ) }

                { this.renderBrand(true) }
                { this.renderConfigurableOptions() }
                { this.renderAddToCartActionBlock() }
            </>
        );
    }
}

export default ProductActionsComponent;
