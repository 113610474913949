import { Suspense } from 'react';

import {
    AddToCart as SourceAddToCart,
    FieldContainer as SourceFieldContainer,
    GroupedProductList as SourceGroupedProductList,
    ProductBundleOptions as SourceProductBundleOptions,
    ProductCompareButton as SourceProductCompareButton,
    ProductComponent as SourceProductComponent,
    ProductConfigurableAttributes as SourceProductConfigurableAttributes,
    ProductCustomizableOptions as SourceProductCustomizableOptions,
    ProductDownloadableLinks as SourceProductDownloadableLinks,
    ProductDownloadableSamples as SourceProductDownloadableSamples,
    ProductReviewRating as SourceProductReviewRating,
    ProductWishlistButton as SourceProductWishlistButton,
} from 'SourceComponent/Product/Product.component';
import { ProductType } from 'SourceComponent/Product/Product.config';
import ProductPrice from 'SourceComponent/ProductPrice';
import TextPlaceholder from 'SourceComponent/TextPlaceholder';
import { TextPlaceHolderLength } from 'SourceComponent/TextPlaceholder/TextPlaceholder.config';
import { ReactElement } from 'SourceType/Common.type';
import { lowPriorityLazy } from 'SourceUtil/Request/LowPriorityRender';

// TODO: implement ProductReviewRating
export const ProductReviewRating = SourceProductReviewRating;

// TODO: implement ProductConfigurableAttributes
export const ProductConfigurableAttributes = SourceProductConfigurableAttributes;

// TODO: implement AddToCart
export const AddToCart = SourceAddToCart;

// TODO: implement FieldContainer
export const FieldContainer = SourceFieldContainer;

// TODO: implement ProductCustomizableOptions
export const ProductCustomizableOptions = SourceProductCustomizableOptions;

// TODO: implement ProductBundleOptions
export const ProductBundleOptions = SourceProductBundleOptions;

// TODO: implement GroupedProductList
export const GroupedProductList = SourceGroupedProductList;

// TODO: implement ProductCompareButton
export const ProductCompareButton = SourceProductCompareButton;

// TODO: implement ProductDownloadableLinks
export const ProductDownloadableLinks = SourceProductDownloadableLinks;

// TODO: implement ProductDownloadableSamples
export const ProductDownloadableSamples = SourceProductDownloadableSamples;

// TODO: implement ProductWishlistButton
export const ProductWishlistButton = SourceProductWishlistButton;

export const QuickAddModal = lowPriorityLazy(
    () => import(
        /* webpackMode: "lazy", webpackChunkName: "product-misc" */
        'Component/QuickAddModal/QuickAddModal.container'
    ),
);

/** @namespace Inov8/Component/Product/Component */
export class ProductComponent extends SourceProductComponent {
    renderName(header = true, dynamic = false): ReactElement {
        const {
            product: { name }, productName, gender, isGendered,
        } = this.props;
        const nameToRender = dynamic ? productName : name;
        // eslint-disable-next-line fp/no-let
        let strippedName: string = '';
        const mensRegex = /mens/gi;
        const womensRegex = /womens/gi;

        if (isGendered && nameToRender) {
            if (gender === 1) {
                strippedName = nameToRender.replace(mensRegex, '');
            } else if (gender === 2) {
                strippedName = nameToRender.replace(womensRegex, '');
            } else {
                strippedName = nameToRender; // Handle other gender cases if necessary
            }
        } else if (nameToRender) {
            strippedName = nameToRender; // If not gendered, keep the name unchanged
        }

        if (!header) {
            return (
                <p block={ this.className } elem="Name">
                    <TextPlaceholder content={ strippedName } length={ TextPlaceHolderLength.MEDIUM } />
                </p>
            );
        }

        return (
            <h1 block={ this.className } elem="Title" itemProp="name">
                <TextPlaceholder content={ strippedName } length={ TextPlaceHolderLength.MEDIUM } />
            </h1>
        );
    }

    renderConfigurableOptions(): ReactElement {
        const {
            setActiveProduct,
            parameters,
            product: { type_id: type, variants = [] },
            inStock,
            getActiveProduct,
            product,
            addToCartTriggeredWithError,
        } = this.props;

        if (type !== ProductType.CONFIGURABLE) {
            return null;
        }

        return (
            <div
              block="ProductActions"
              elem={ `AttributesWrapper  
                    ${ !product?.attributes?.shoe_width && !product?.attributes?.shoe_size ? ' noWidthOption' : '' }
                    ${ parameters.shoe_width ? ' selectedWidth' : '' }
                    ${ (parameters.shoe_size || parameters.size) ? ' selectedSize' : ''}
                    ${ product.sku === 'ECOM-SUB' ? 'selectedWidth selectedSize' : ''} ` }
            >
                <Suspense fallback={ null }>
                    <ProductConfigurableAttributes
                        // eslint-disable-next-line no-magic-numbers
                      numberOfPlaceholders={ [2, 4] }
                      updateAddToCartTriggeredWithError={ false }
                      addToCartTriggeredWithError={ addToCartTriggeredWithError }
                      mix={ { block: this.className, elem: 'Attributes' } }
                      parameters={ parameters }
                      variants={ variants }
                      updateConfigurableVariant={ setActiveProduct }
                      configurable_options={ this.getConfigurableAttributes() }
                      isContentExpanded
                      inStock={ inStock }
                      showProductAttributeAsLink={ false }
                      product={ product }
                      getActiveProduct={ getActiveProduct() }
                    />

                </Suspense>

            </div>
        );
    }

    renderCompareButton(): ReactElement {
        const { product: { id } } = this.props;

        if (!id) {
            return null;
        }

        return (
            <Suspense fallback={ null }>
                <ProductCompareButton
                  productId={ id }
                  mix={ {
                      block: this.className,
                      elem: 'ProductCompareButton',
                      mods: { isGrey: true },
                  } }
                />
            </Suspense>
        );
    }

    renderPrice(isPreview = false): ReactElement {
        const {
            getActiveProduct, productPrice, parameters, isPdp,
        } = this.props;
        const product = getActiveProduct();
        const {
            type_id: type,
            price_tiers: priceTiers,
        } = product;

        if (!productPrice) {
            return null;
        }

        return (
            <div
              block={ this.className }
              elem="PriceWrapper"
            >
                <ProductPrice
                  price={ productPrice }
                  priceType={ type as ProductType }
                  tierPrices={ priceTiers }
                  isPreview={ isPreview }
                  mix={ { block: this.className, elem: 'Price' } }
                  parameters={ parameters }
                  product={ product }
                  isPdp={ isPdp }
                />
            </div>
        );
    }
}

export default ProductComponent;
